import React, { useState, useRef } from "react"
import { useForm } from "react-hook-form"
import { Row, Col, Form, Button } from "react-bootstrap"

const SignIn = () => {
  const [isSignIn, setIsSignIn] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const scrollToRef = ref => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }
  const myRef = useRef(null)

  const onSubmit = (data, event) => {
    setIsSignIn(true)
    scrollToRef(myRef)
  }

  return (
    <section className="container sign-in" ref={myRef}>
      {isSignIn ? (
        <div className="message-block">
          <h5 className="first-letter submission-message text-center">
            DING! WE GOT YOUR SUBMISSION—BE SURE TO KEEP AN EYE ON YOUR INBOX TO
            SEE IF YOU’RE A WINNER!
          </h5>
        </div>
      ) : (
        <>
          <h5 className="text-white fw-bold">
            SIGN UP AND STAY FRESH YEAR-ROUND
          </h5>
          <p className="text-white">
            Be in-the-know with all things ORBIT® Gum and Mars Wrigley brands
            for future sweepstakes, giveaways and more chances to win. Make sure
            you’re following us on social.
            <span className="d-block">
              Enter your information below to get your Ding back 24/7.
            </span>
          </p>
          <Form className="home" onSubmit={handleSubmit(onSubmit)}>
            <Row noGutters className="justify-content-center">
              <Col>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="firstName" className="mb-4">
                      <Form.Control
                        type="text"
                        maxLength="60"
                        className="rounded-0"
                        placeholder="First Name"
                        {...register("firstname", { required: true })}
                      />
                      {errors.firstname && (
                        <span className="input-error">
                          First Name is required
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="lastName" className="mb-4">
                      <Form.Control
                        type="text"
                        maxLength="60"
                        className="rounded-0"
                        placeholder="Last Name"
                        {...register("lastname", { required: true })}
                      />
                      {errors.lastname && (
                        <span className="input-error">
                          Last Name is required
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="email" className="mb-4">
                      <Form.Control
                        type="text"
                        maxLength="80"
                        className="rounded-0"
                        placeholder="E-mail Address"
                        {...register("email", {
                          required: "Email ID is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid Email Address",
                          },
                        })}
                      />
                      {errors.email && (
                        <span className="input-error">
                          {errors.email.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group>
                  <Row className="align-center">
                    <Col md={1} className="col-2">
                      <input
                        type="checkbox"
                        id="marketingConsent"
                        className="mt-1 checkbox-consent"
                        {...register("marketingConsent")}
                      />
                    </Col>
                    <Col
                      md={11}
                      className="col-10 p-0 align-self-center text-left"
                    >
                      <label htmlFor="marketingConsent">
                        <p className="mb-2 checkbox-msg text-white">
                          I would like to receive future marketing
                          communications from ORBIT® Gum and other Mars
                          Wrigley’s brands via the email provided. You can
                          withdraw this consent at any time.
                        </p>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>
                <Button size="lg" className="my-4" type="submit">
                  SUBMIT
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </section>
  )
}

export default SignIn
