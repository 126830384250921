import React, { Component } from "react"
import Slider from "react-slick"

import CarouselOne from "../images/carousel1.png"
import CarouselTwo from "../images/carousel2.png"
import CarouselThree from "../images/carousel3.png"
import CarouselFour from "../images/carousel4.png"
import CarouselFive from "../images/carousel5.png"
import CarouselSix from "../images/carousel6.png"
import CarouselSeven from "../images/carousel7.png"
import CarouselEight from "../images/carousel8.png"

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return <div className={className} onClick={onClick} />
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return <div className={className} onClick={onClick} />
}
export default class SweaterCarousel extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      lazyLoad: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            initialSlide: 2,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }

    return (
      <section className="position-relative text-center">
        <Slider {...settings}>
          <div>
            <img
              className="carousel-slide"
              src={CarouselEight}
              alt="Carousel Slide"
            />
          </div>
          <div>
            <img
              className="carousel-slide"
              src={CarouselOne}
              alt="Carousel Slide"
            />
          </div>
          <div>
            <img
              className="carousel-slide"
              src={CarouselTwo}
              alt="Carousel Slide"
            />
          </div>
          <div>
            <img
              className="carousel-slide"
              src={CarouselThree}
              alt="Carousel Slide"
            />
          </div>
          <div>
            <img
              className="carousel-slide"
              src={CarouselFour}
              alt="Carousel Slide"
            />
          </div>
          <div>
            <img
              className="carousel-slide"
              src={CarouselFive}
              alt="Carousel Slide"
            />
          </div>
          <div>
            <img
              className="carousel-slide"
              src={CarouselSix}
              alt="Carousel Slide"
            />
          </div>
          <div>
            <img
              className="carousel-slide"
              src={CarouselSeven}
              alt="Carousel Slide"
            />
          </div>
        </Slider>
      </section>
    )
  }
}
